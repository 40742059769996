.app-loader {
  z-index: 1046;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
.lastWeekData {
  color: #4d5251;
}
th.sortable {
  padding-right: 25px;
}
.pcoded-navbar {
  overflow-y: auto !important;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.react-bootstrap-table {
  overflow: auto;
}
.result_style {
  padding: 0px !important;
}
.label-success {
  text-wrap: nowrap;
}
.label.label-primary {
  text-wrap: nowrap;
}
