

a{
  text-decoration: none;
}

.mobile-menu.on {
  z-index: 99;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px dashed #c7c3c3;
  background-color: #fafafa;
  color: #bdbdbd;

  margin-bottom: 20px;
}

.accept {
  border-color: #107c10 !important;
}

.reject {
  border-color: #d83b01 !important;
}
.lg-bc{
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}

.input-error {
  border: 2px solid #dc3545 !important;
}

.upload-image-preview {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;


  .image-preview {
    object-fit: cover;
    width: 100%;
    height: 100%;
    padding: 3px;
    border: 1px dashed black;
  }
}

.input-file-preview{
  width: 45px;
  height: 30px;
}

.group-array-fields{
  width: 100%;
  padding: 10px;
  border: 1px solid #d6d6d5;
  background-color: #d9d9d977;
}
.group-array{
  width: 100%;
  padding: 10px;
  border: 2px solid #b1b1af;
}

//bs4 menu
.dropdown-menu-right {
  right: 0;
  left: auto;
}

.custom-toggle .form-check-input:checked {
  background-color: #04a9f5; /* Change to your desired color */
  border-color: #04a9f5;    /* Optional: Border color */
}

// .custom-toggle .form-check-input {
//   background-color: #d9534f; /* Change off-state color */
// }